<template>
  <div class="w100p" v-if="!loading">
    <h1 class="text-left f2-5 ma0 f1-5-mb mt2-mb">{{ isNewUser? 'Set ': 'Reset' }} Password</h1>
    <p class="font-axiroma-book text-left mb4 text-grey f1-3 mt0 mb1-mb">Enter a new password.</p>
    <div class="border-grey">
    <div class="display-flex border-bottom w100p h6" :class="{ 'bg-grey br0-5 ': user.password.length }">
      <bh-input
        type="password"
        class="w100p pl2"
        placeholder="Password"
        :value="user.password"
        @output="updateValue(user, 'password', $event)"
        required
        />
      <bh-icon v-if="isPasswordsValid" icon="check-mark" class="inline f2 pa2 pa1-mb mt0--5 mt0-mb text-success" />
    </div>
    <div class="display-flex w100p h6"  :class="{ 'bg-grey br0-5 ': user.confirmPassword.length }">
      <bh-input
      class="w100p pl2"
      type="password"
      placeholder="Re-enter Password"
      :value="user.confirmPassword"
      @output="updateValue(user, 'confirmPassword', $event)"
      @keydown.enter="submit"
      required
      />
      <bh-icon v-if="isPasswordsValid" icon="check-mark" class="inline f2 pa2 pa1-mb mt0--5 mt0-mb text-success" />
    </div>
    </div>
    <div class="mt5 display-flex justify-between">
      <router-link to="/login">
        <bh-button class="pa1 w20 h6 br0-5 f1-6 text-violet border-violet box-shadow-light">Back to login</bh-button>
      </router-link>
      <bh-button
        @output="submit"
        :disabled="!isPasswordsValid"
        class="pa1 w20 h6 br0-5 f1-6 bg-violet text-white border-violet box-shadow-light"
        btnClass="w100p h6 br0-5 bg-violet text-white f2 pointer justify-center align-center border-none"
        >
        <bh-loader v-if="saving" color="#ffffff"/>
        <span v-else>Save</span>
      </bh-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { updateValue, validatePassword } from '@/utils'
import { User } from '@/services/user'
import cookies from '@/services/cookies'

export default {
  name: 'tbh-reset-password',
  data() {
    return {
      user: {
        password: '',
        confirmPassword: ''
      },
      isNewUser: false,
      saving: false,
      userEmail: ''
    }
  },
  computed: {
    ...mapGetters(['loading']),
    /**
     * @method isPasswordsValid
     * checks whether password and confirm password are the same and does validations
     * @returns { Object }
    */
    isPasswordsValid() {
      const { password, confirmPassword } = this.user
      return validatePassword(password.trim()) && password === confirmPassword
    }
  },
  mounted () {
    const { isNewUser, access_token } = this.$route.query
    this.isNewUser = isNewUser === 'true'
    this.userEmail = this.parseJwt(access_token).email
    if (access_token) {
      cookies.set('jwt_token', access_token, 600)
      setTimeout(() => {
        cookies.delete('jwt_token')
      }, 600000) // Cookies expiration time, 10 min.
    }
  },
  methods: {
    ...mapActions(['setSingleProperty']),
    ...mapActions(['loginToApp', 'fetchFeedbackReasons']),
    updateValue,

    /**
     * @method submit
     * @param - event - { Object }
     */
    async submit() {
      if (!this.isPasswordsValid) return
      let toast = null
      try {
        this.saving = true
        await User.resetPassword({ password: this.user.password })
        toast = { duration: 3000, show: true, message: 'Your password has been set successfully.' }
        this.login()
      } catch (err) {
        toast = { duration: 3000, show: true, message: err?.error?.message || 'Invalid Details, Please check', color: '#F44336' }
      }
      this.user = { password: '', confirmPassword: '' }
      this.saving = false
      this.setSingleProperty(['toast', toast])
    },

    /**
     * @method login
     * Login after password is set
     * @param - {}
    */
    async login() {
      const setUserAndGoToRoute = (data) => {
        const validData = data && data.access_token && data.user
        if (validData) {
          const routeToGoTo = (this.$route['query'].redirect) ? this.$route['query'].redirect : '/'
          if (routeToGoTo) this.$router.push(routeToGoTo)
          this.fetchFeedbackReasons()
        } else {
          this.user.password = ''
          this.errorMessage = 'Access Denied'
        }
      }
      try {
        await this.loginToApp({ loginParams: { email: this.userEmail, password: this.user.password }, callback: setUserAndGoToRoute })
      } catch (err) {
        this.errorMessage = err?.error?.message
        this.user.password = ''
      }
    },

    parseJwt(token) {
      try {
        return JSON.parse(atob(token.split('.')[1]))
      } catch (e) {
        return null
      }
    }
  }
}
</script>
